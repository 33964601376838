import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";

import { Subscription } from "rxjs";
import {
  tap,
  startWith,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  connected: boolean = false;
  raisedHand: boolean = false;
  audience: any = [];
  audienceIds: any = {}; // Ids of users raised hands previously (for detection of hands downs)

  websocket: WebSocket = null;
  websocketReconnectionTimeout: number = 5;

  volumeEnabled: boolean = false;
  vibrationEnabled: boolean = false;

  volumeLabel: string = "Volupe up";
  vibrationLabel: string = "Enable vibration";
  handLabel: string = "Raise your hand";

  intervalObject = null;

  constructor() {
    this.intervalObject = setInterval(() => {
      if (this.connected) {
        this.websocket.send(JSON.stringify({ msg: "ping" })); // Send raise message
        console.log("Ping time (done)");
      } else {
        console.log("Ping time (skipped)");
      }
    }, 60000); // Ping backend every 60 seconds
  }
  connect(channel: string, fullName: string) {
    // Create websocket object
    this.websocket = new WebSocket("wss://iraisemyhand.com/wapi");

    // Implement connection handler (onopen)
    this.websocket.onopen = (event) => {
      this.websocket.send(
        JSON.stringify({ msg: "join", channel: channel, fullName: fullName })
      ); // Send join message
      this.connected = true;
      console.log("Connected: " + this.connected);
      console.log("Backend - connected");
    };

    // Implement event handlers (onerror)
    this.websocket.onerror = (err) => {
      this.connected = false;
      this.raisedHand = false;
      this.audience = [];
      console.log("Backend - error");
    };

    // Implement event handlers (onclose)
    this.websocket.onclose = () => {
      console.log("Backend - disconnected");
      this.connected = false;
      this.raisedHand = false;
      this.audience = [];
    };

    // Implement message handler
    this.websocket.onmessage = (event) => {
      try {
        var message = JSON.parse(event.data);
        //console.log("Message received");
        //console.log(message);
        var notificationRequired = false;
        if (message.raisedHands) {
          // Record ids
          var audienceIds = {};
          for (const raisedHand of message.raisedHands) {
            audienceIds[raisedHand.id] = true;
            // Check if notification required (if there is a new hand raised)
            if (!(raisedHand.id in this.audienceIds)) {
              notificationRequired = true;
            }
          }

          // Sort by their reaction time
          message.raisedHands.sort((a: any, b: any) =>
            a.reactedOn > b.reactedOn ? 1 : b.reactedOn > a.reactedOn ? -1 : 0
          );
          // Assign
          this.audience = message;
          this.audienceIds = audienceIds;
        }

        // Vibrate if enabled
        if (notificationRequired && this.vibrationEnabled) {
          window.navigator.vibrate(200); // vibrate during 200ms
        }

        // Sound alert if enabled
        if (notificationRequired && this.volumeEnabled) {
          var sound = new Audio("/assets/juntos.mp3");
          sound.play();
        }
      } catch (err) {
        console.error(err);
        console.error("Backend - unkown message format");
      }
    };
  }

  toggleMute() {
    this.volumeEnabled = !this.volumeEnabled;
    if (this.volumeEnabled) {
      this.volumeLabel = "Volume up";
    } else {
      this.volumeLabel = "Mute volume";
    }
  }

  toggleVibration() {
    this.vibrationEnabled = !this.vibrationEnabled;
    if (this.vibrationEnabled) {
      this.vibrationLabel = "Disable vibration";
    } else {
      this.vibrationLabel = "Enable vibration";
    }
  }

  toggleHand() {
    if (this.connected) {
      this.raisedHand = !this.raisedHand;
      this.websocket.send(
        JSON.stringify({ msg: "raise", value: this.raisedHand })
      ); // Send raise message

      if (this.raisedHand) {
        this.handLabel = "Lower your hand";
      } else {
        this.handLabel = "Raise your hand";
      }
    }
  }

  back() {
    this.websocket.close();
    this.websocket = null;

    this.connected = false;
    this.raisedHand = false;
    this.audience = [];
  }
}
