import { Component, OnInit } from "@angular/core";

import { ApplicationService } from "./application.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  channel: string = "ai";
  fullName: string = "";
  validated: boolean = false;

  constructor(private applicationService: ApplicationService) {}
  ngOnInit() {
    // Nothing to do at this stage
  }

  onJoin() {
    this.applicationService.connect(this.channel, this.fullName); // Connect to backend application via web sockets
  }

  onBack() {
    this.applicationService.back();
  }

  onToggleMute() {
    this.applicationService.toggleMute();
  }

  onToggleVibration() {
    this.applicationService.toggleVibration();
  }

  onToggleHand() {
    if (this.channel.length > 0 && this.fullName.length > 0) {
      this.applicationService.toggleHand();
    }
  }
}
